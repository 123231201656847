
import { getDictionary } from '~/utils/butterUtils'

export default {
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    }
  }
}
